import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const Introduction = () => {
  return (
    <LayoutComponent>
      <h1>Introduction</h1>
      <p>
        GAINSS dashboard is designed to provide real-time metrics for your
        testing processes. This dashboard offers comprehensive insights into key
        metrics such as test case coverage, execution status, and test cycle
        progress, etc.
      </p>
      <p>
        With clear visualizations, this helps teams streamline their testing
        efforts, ensuring better coverage, faster decision-making, and
        higher-quality software releases.
      </p>
    </LayoutComponent>
  )
}

export default Introduction
